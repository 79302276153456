.property-thermal-images.card .embla__slide__inner {
	display: flex;

	.thermal-image-details-container {
		display: flex;
		flex-direction: column;

		& > div {
			flex-basis: 50%;
		}
	}

	.embla__slide__img {
		max-width: 20vw;
	}
}
